@import "frontend/pwa/sass/_variables.scss";

@import '~main/styles/select.scss';

.live-send-tip-controls {
  background-color: rgba(20, 20, 20, .8);
  border-radius: 7px;
  color: #ffffff;
  display: flex;
  height: 60px;
  font-family: gotham_boldregular;
  font-size: 10px;
  letter-spacing: 1px;
  text-transform: uppercase;

  > div {
    flex-grow: 1;
    flex-basis: 33%;
  }

  select {
    appearance: none;
    background-color: transparent;
    background-image: $select-arrow;
    background-repeat: no-repeat, repeat;
    background-position: right .7em top 50%, 0 0;
    background-size: .65em auto, 100%;
    border: none;
    color: #ffffff;
    font-family: gotham_boldregular;
    font-size: 12px;
    letter-spacing: 1px;
    margin-top: 8px;
    text-transform: uppercase;
    width: 100%;
  }

  .balance-amount-container {
    border-right: 1px solid #666666;
    padding: 10px;
  }

  .balance {
    color: #666666;
    font-family: gotham_boldregular;
    letter-spacing: 1;
    text-transform: uppercase;
  }

  .amount {
    font-size: 12px;
  }

  .privacy-container {
    border-right: 1px solid #666666;
    font-family: gotham_boldregular;
    height: 100%;
    padding: 10px;
    text-transform: uppercase;

    .privacy-heading {
      color: #666666;
      font-size: 10px;
    }
  }

  .tip-model {
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 10px;

    button {
      background-color: #547b00;
      border-radius: 20px;
      color: #ffffff;
      font-family: gotham_boldregular;
      font-size: 11px;
      letter-spacing: 2px;
      padding: 8px 20px 8px 20px;
      text-transform: uppercase;

      &:disabled {
        background-color: #414141;
      }
    }
  }

  &.small {
    font-size: 8px;
    height: 50px;

    .balance-amount-container {
      padding: 8px;
    }

    .balance {
      :first-child {
        display: none;
      }
    }

    .amount {
      font-size: 10px;
    }

    select {
      font-size: 10px;
    }

    .privacy-container {
      padding: 8px;

      .privacy-heading {
        font-size: 8px;
      }
    }

    .tip-model {
      padding: 8px;

      button {
        font-size: 8px;
      }
    }
  }

  &.tiny {
    font-size: 7px;
    height: 50px;

    .balance-amount-container {
      padding: 7px;
    }

    .balance {
      :first-child {
        display: none;
      }
    }

    .amount {
      font-size: 8px;
    }

    select {
      font-size: 8px;
    }

    .privacy-container {
      padding: 7px;

      .privacy-heading {
        font-size: 7px;
      }
    }

    .tip-model {
      padding: 7px;

      button {
        font-size: 7px;

        span {
          display: none;
        }
      }
    }
  }

  &.micro {
    font-size: 9px;
    height: 125px;
    flex-direction: column;

    .balance-amount-container {
      border-right: initial;
      padding: 5px;
      text-align: center;
    }

    .privacy-container {
      border-right: initial;
    }

    .amount {
      font-size: 10px;
    }

    select {
      font-size: 10px;
      margin-top: 5px;
    }

    .privacy-container {
      padding: 5px;
      text-align: center;

      .privacy-heading {
        font-size: 9px;
      }
    }

    .tip-model {
      padding: 7px;

      button {
        font-size: 9px;
        padding: 5px 10px 5px 10px;
      }
    }
  }

  &.supermicro {
    font-size: 7px;
    height: 125px;
    flex-direction: column;

    .balance-amount-container {
      border-right: initial;
      padding: 5px;
      text-align: center;
    }

    .privacy-container {
      border-right: initial;
    }

    .amount {
      font-size: 8px;
    }

    select {
      font-size: 8px;
      margin-top: 5px;
    }

    .privacy-container {
      padding: 5px;
      text-align: center;

      .privacy-heading {
        font-size: 7px;
      }
    }

    .tip-model {
      padding: 7px;

      button {
        font-size: 7px;
        padding: 5px 10px 5px 10px;
      }
    }
  }
}
