@import "main/styles/index.less";

.wrapper {
  padding-bottom: 15%;
  position: relative;
  min-height: 200px;

  @media @phone-only {
    min-height: 100px;
    padding-bottom: 32%;
  }

  > a, > a > * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  a > * {
    align-items: center;
    background-size: cover;
    background-position: center center;
    display: flex;
    justify-content: center;
    cursor: pointer;
  }
}

button.icon-close {
  background-color: transparent;
  position: absolute;
  padding: 15px;
  top: 0px;
  right: 0px;

  @media @phone-only {
    padding: 8px;
  }
}
