@import "main/styles/index.less";

.livechat {
  background-color: @background-grey;
  display: flex;
  flex-direction: column;
}

.new-messages-indicator-container {
  position: relative;

  .new-messages-indicator {
    bottom: 75px;
    cursor: pointer;
    position: absolute;
    width: 100%;
  }
}

.comments {
  flex-grow: 1;
  height: 1px;
  overflow-y: scroll;
  padding: 0;
  position: relative;
}

.comment {
  border-bottom: 1px solid @background-shaded-grey;
  display: flex;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
  padding-bottom: 10px;

  &:first-child {
    margin-top: 0px;
  }

  &.staff {
    background-color: #e1f1db;
  }

  &.suicidegirl {
    background-color: #f9dbe2;
  }

  &.hopeful {
    background-color: #eae1ef;
  }

  &.photographer {
    background-color: #f0e9d6;
  }
}

.avatar {
  flex-grow: 0;
  margin-right: 20px;

  img {
    height: 40px;
    width: 40px;
  }
}

.comment-details {
  flex-grow: 1;
}

.username-cog-split {
  display: flex;
}

.username {
  flex-grow: 1;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.icon-cog {
  color: #9e9e9e;
  font-size: 14px;

  &:hover {
    color: #414141;
  }
}

.comment-form {
  border-top: 1px solid @background-shaded-grey;
  bottom: 0;
  flex-grow: 0;
  padding: 10px;
  width: 100%;

  input {
    width: 100%;
  }
}

.options-menu-container {
  position: relative;
  width: 100%;
}

.options-menu {
  background: #999999;
  border: 1px solid #BDBDBD;
  display: block;
  list-style: none;
  margin: 0;
  padding: 0;
  position: absolute;
  width: 200px;
  z-index: 999999;

  &:focus {
    outline: none;
  }

  li {
    margin: 0;

    a {
      background-color: #999999;
      color: @white;
      display: block;
      font-family: gotham_boldregular;
      font-size: 12px;
      height: 100%;
      letter-spacing: 1px;
      padding: 10px;
      text-align: center;
      text-transform: uppercase;
      width: 100%;

      &:hover {
        background: #000000;
        color: #de879e;
      }
    }

    &:last-child {
      &:after {
        content: '\0020';
        display: block;
        width: 0;
        height: 0;
        border: 0 none;
        border-style: solid;
        border-width: 5px 0px 5px 5px;
        border-color: transparent;
        border-left-color: #999999;
        position: absolute;
        right: -5px;
        top: 12px;
      }
    }
  }
}
