@import "frontend/pwa/sass/_variables.scss";

.new-messages-indicator {
  background-color: #775a62;
  display: flex;
  justify-content: space-around;

  span {
    color: white;
    font-family: gotham_boldregular;
    font-size: 14px;
    padding: 10px;
    text-align: center;
    text-transform: uppercase;
  }

  .down-arrow:after {
    content: '\0020';
    display: block;
    width: 0;
    height: 0;
    border: 0 none;
    border-style: solid;
    border-width: 10px 10px 0 10px;
    border-color: transparent;
    border-top-color: #aa989d;
    font-size: 14px;
    margin-top: 6px;
  }

  &.small {
    span {
      font-size: 11px;
      padding: 8px;
    }

    .down-arrow:after {
      border-width: 7px 7px 0 7px;
      margin-top: 2px;
    }
  }

  &.tiny {
    span {
      font-size: 9px;
      padding: 6px;
    }

    .down-arrow:after {
      border-width: 5px 5px 0 5px;
      margin-top: 1px;
    }
  }

  &.micro {
    span {
      font-size: 6px;
      padding: 3px;
    }

    .down-arrow:after {
      border-width: 3px 3px 0 3px;
      margin-top: 1px;
    }
  }
}
