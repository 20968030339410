@import "main/styles/index.less";

.icon-checkmark.checked {
  background-color: #93b867;
}

.label {
  display: inline;
  font-family: gotham_boldregular;
  letter-spacing: 1px;
  margin: 1rem 1rem 1rem 2.5rem;
  text-transform: uppercase;
}
