@import "main/styles/index.less";

.live-icon {
  border-radius: 25px;
  background-color: @medium-dark-grey;
  font-size: 10px;
  display: flex;
  align-items: center;
  padding: 3px 10px;

  .led {
    color: @green;
    font-size: 14px;
    padding-right: 3px;
  }

  .live-icon-label {
    color: @white;
    font-family: gotham_bookregular;
    letter-spacing: 1px;
    text-transform: uppercase;
    padding: 0;
  }
}
