@import "main/styles/index.less";

h1.loading {
  margin: auto;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
}

h1 {
  color: @background-grey;
  font-size: 14px;
  letter-spacing: 2px;
  margin-top: 0;
  padding-top: 50px;
  text-align: center;
  text-transform: uppercase;

  @media @phoneLandscape {
      padding-top: 25px;
  }
}

hr {
  border: 0;
  height: 1px;
  background-color: #707070 ;
  margin-top: 25px;
  margin-bottom: 25px;
  width: 90%;

  @media @landscape {
    width: 500px;
  }

  @media @phoneLandscape {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

.video-feed {
  position: relative;
  height: 100%;
  left: 0;
  top: 0;
  width: 100%;
}

#videowrap {
  opacity: 0.15;
  height: 100%;
  width: 100%;

  &.streaming {
    opacity: 1;
  }
}

#myvideo {
  bottom: 0;
  height: 100%;
  left: 0;
  margin: auto;
  object-fit: contain;
  object-position: center center;
  top: 0;
  width: 100%;
  z-index: 0;
}

.timer {
  bottom: 5px;
  color: black;
  display: inline-block;
  left: 0;
  margin: auto;
  opacity: 0.6;
  position: absolute;
  right: 0;
  text-align: center;
}

.publisher-preferences {
  left: 0;
  position: absolute;
  text-align: center;
  top: 0;
  width: 100%;
  z-index: 100;

  label {
    color: @background-grey;
    font-size: 12px;
    letter-spacing: 2px;
  }

  @media @phone-only {
    hr:last-child {
      display: none;
    }
  }
}

.btn {
  border-radius: 25px;
}

.c-options {
  align-items: center;
  display: flex;
  flex-direction: column;

  @media @phone-only {
    width: 100%;
  }

  label {
    float: left;
    margin-bottom: 5px;
    padding-top: 6px;
    text-align: left;
    width: 150px;
  }

  select {
    float: left;
    margin-bottom: 5px;
    margin-top: 5px;
    width: 175px;
  }

  .form-group {
    display: flex;

    @media @phone-only {
      width: 100%;
    }
  }

  button {
    border-radius: 25px;
  }
}

#devices {
  margin-top: 15px;
}

.stream-preference {
  color: #ffffff;
  float: left;
  font-family: gotham_boldregular;
  font-size: 12px;
  letter-spacing: 2px;
  line-height: 20px;
  text-transform: uppercase;

  .preference-option {
    display: inline-block;
    text-align: left;
    width: 200px;

    .radio-button {
      &:before {
        color: #ffffff;
        content: '○';
        font-size: 29px;
        line-height: 14px;
        position: relative;
        top: 5px;
      }

      &.selected:before {
        color: #a6d42e;
        content: '●';
      }
    }
  }

  @media @phone-only {
    display: block;
    float: none;
    text-align: left;
    width: 100%;

    .preference-option {
      display: block;
      padding: 0 15px 20px 15px;
      margin-left: 0;
      width: auto;

      &:last-child {
        padding-bottom: 0;
      }

      .radio-button {
        margin-left: 0;

        &:before {
          font-family: Arial;
          top: 4px;
        }
      }
    }
  }
}

.save-stream, .stream-type {
  margin-top: 20px;
}

.actions {
  bottom: 40px;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  text-align: center;

  @media @beforeIpad {

    display: flex;
    justify-content: space-between;
    flex-direction: column-reverse;
    bottom: 10px;

    button {
      margin: 15px;
    }
  }

  .fullscreen {
    background: transparent;
    float: left;
    margin-left: 20px;
  }

  .go-live-cta {
    background-color: @background-grey;
    border-radius: 25px;
    color: #2c2c2c;
    padding: 15px 75px;
  }

  .end-stream {
    background-color: red;
    border-radius: 25px;
    color: @white;
  }

  .mute {
    border-radius: 25px;
    bottom: -5px;
    padding-left: 25px;
    padding-right: 25px;
    position: absolute;
    right: 20px;

    @media @phone-only {
      bottom: initial;
      right: initial;
      position: initial;
    }
  }

  .muted {
    background-color: @darker-green;
  }

  .switch-camera {
    border-radius: 25px;
    bottom: -5px;
    padding-left: 25px;
    padding-right: 25px;
    position: absolute;
    left: 20px;

    @media @beforeIpad {
      bottom: initial;
      left: initial;
      margin-bottom: 10px;
      position: initial;
    }
  }
}

.streaming {
  .fullscreen {
    bottom: 0;
    color: @white;
    font-size: 18px;
    left: 10px;
    position: absolute;
  }

  .actions {
    @media @phone-only {
      bottom: 40px;
      flex-direction: row;
      margin-bottom: initial;

      button {
        margin: initial;
      }

      .switch-camera {
        margin-bottom: initial;
      }
    }

    @media @phone-to-landscape {
      display: initial;
      .end-stream {
        width: 150px;
      }
      .mute {
        right: 5px;
      }
    }
  }
}
