@import "main/styles/index.less";

.modal-container {
  background-color: #2c2c2c;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
  font-family: Helvetica, Arial, sans-serif;
  height: 500px;
  margin: 0px auto;
  padding: 0;
  position: relative;
  transition: all .3s ease;
  width: 800px;

  .split-area-2 {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .livechat {
    position: initial;
    flex-grow: 1;
    width: 100%;
  }

  &.fullscreen {
    height: 100%;
    width: 100%;
  }

  .btn {
    border-radius: 25px;
  }
}

.video-feed-container {
  height: 100%;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.user-info {
  color: @white;
  display: none;
  overflow: hidden;
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;

  @media @phoneLandscape {
    display: block;
  }

  h2.decorated {
    display: none;
    font-size: 22px;
    left: 50%;
    letter-spacing: 2px;
    margin: 0;
    position: absolute;
    text-align: center;
    text-transform: uppercase;
    top: 50%;
    transform: translate(-50%,-50%);

    @media @phoneLandscape {
      display: block;
    }

    &:before {
      display: block;
      font-size: 20px;
      margin-bottom: 10px;
      position: static;
    }
    &:after {
      display: block;
      font-size: 20px;
      margin-top: 10px;
      position: static;
    }
  }
}
