@import "main/styles/index.less";

.fs-container {
  height: 100%;
  width: 100%;
}

.video-feed {
  position: relative;
  height: 100%;
  left: 0;
  top: 0;
  width: 100%;
}

&.supermicro {
  .actions {
    button.icon-close {
      font-size: 8px;
      left: 0;
      margin: 0;
      padding: 4px;
    }

    button.mute {
      bottom: 5px;
      font-size: 6px;
      height: 18px;
      padding: 4px;
      margin: 0;
      right: 5px;
    }

    button.fullscreen {
      bottom: 10px;
      left: 5px;
      margin: 0;
      padding: 0;
    }

    button.tip {
      font-size: 12px;
      right: 0;
      margin: 0;
      padding: 4px;
    }

    .live-send-tip-controls {
      display: none;
    }
  }
}

&.micro {
  .actions {
    button.icon-close {
      left: -1px;
      margin: 0;
      padding: 5px;
    }

    button.mute {
      font-size: 8px;
      height: 20px;
      margin-bottom: 10px;
      padding: 5px;
      right: 0;
    }

    button.fullscreen {
      bottom: 0;
      left: 0;
      margin: 0;
    }

    button.tip {
      font-size: 16px;
      right: 1px;
      margin: 3px;
      padding: 5px;
    }

    .live-send-tip-controls {
      display: none;
    }
  }
}

&.tiny {
  .actions {
    button.mute {
      bottom: 0;
      font-size: 12px;
      height: 31px;
      margin-bottom: 10px;
      padding: 8px;
      right: 0;
    }

    button.fullscreen {
      bottom: 10px;
      font-size: 16px;
      left: 0;
      margin: 0;
    }

    button.tip {
      margin: 8px;
    }
  }
}

&.small-desktop {
  .actions {
    button.fullscreen {
      bottom: 0;
      left: 0;
      margin-left: 0;
    }
    button.mute {
      font-size: 10px;
      padding-top: 5px;
      padding-bottom: 5px;
      padding-left: 10px;
      padding-right: 10px;
    }
  }
}

.ended {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  background: #000;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

#videowrap {
  height: 100%;
  width: 100%;
}

#myvideo {
  bottom: 0;
  height: 100%;
  left: 0;
  margin: auto;
  object-fit: contain;
  object-position: center center;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 0;

  @media @phoneLandscape {
    object-position: left top;
  }
}

.actions {
  height: 100%;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%,-50%);

  @media @phoneLandscape {
    left: 0;
    top: 0;
    transform: none;
  }

  button {
    margin: 15px;
  }

  .icon-close {
    background-color: rgba(0, 0, 0, .4);
    position: absolute;
    top: 0;
    left: 0;
    margin-left: 0;
    margin-top: 0;
  }

  .fullscreen {
    background: transparent;
    position: absolute;
    left: 0;
    bottom: 0;
    font-size: 20px;
  }

  .mute {
    background-color: rgba(0, 0, 0, .4);
    border-radius: 25px;
    bottom: 0;
    padding-left: 25px;
    padding-right: 25px;
    position: absolute;
    right: 0;
  }

  .muted {
    background-color: @darker-green;
  }

  .tip {
    background-color: transparent;
    position: absolute;
    font-size: 20px;
    top: 0;
    right: 0;
    margin: 10px;
    padding: 0;

    &:hover {
      &:before {
        color: #ffffff;
      }
    }

    &:before {
      background-color: transparent;
    }
  }

  .live-send-tip-controls {
    bottom: 20px;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
  }
}
