@import "main/styles/index.less";

.user-info-desktop {
  display: none;

  @media @overIpadLanscape {
    display: block;
  }

  .watching {
    background-color: #3d3d3d;
    color: #919191;
    font-size: 10px;
    letter-spacing: 1px;
    padding: 7px 10px 7px 10px;
    text-transform: uppercase;
  }

  .details {
    display: flex;
    padding: 20px 10px 20px 10px;
  }

  .avatar {
    border-radius: 50%;
    margin-right: 10px;
    height: 30px;
    width: 30px;
  }

  .user-location-container {
    flex-grow: 1;
  }

  .username {
    color: @pink;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 1px;
    text-transform: uppercase;
    word-break: break-all;
  }

  .location {
    color: #949494;
    font-size: 10px;
    letter-spacing: 1px;
    margin-top: 5px;
    text-transform: uppercase;
  }

  .icon-cross {
    background-color: #2c2c2c;
    color: #93b868;
    font-size: 18px;
  }
}

.narrow {
  .watching {
    font-size: 8px;
  }

  .avatar {
    height: 20px;
    width: 20px;
  }

  .username {
    font-size: 10px;
  }

  .location {
    font-size: 8px;
  }

  .icon-cross {
    font-size: 10px;
  }
}
