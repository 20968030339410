@import "main/styles/index.less";

@streams-height: 140px;
@banner-background: #272526;
@circle-size: 75px;
@bar-height: 35px;
@bar-text-color: #a0a786;

#banner {
  background: @banner-background;
  color: #fff;
}

* {
  font-family: gotham_boldregular;
}

.expand-enter-active, .expand-leave-active {
  transition: all .3s ease;
  height: @streams-height;
  overflow: hidden;
}
.expand-enter, .expand-leave-to {
  height: 0;
  overflow: hidden;
}

.layout-inner {
  padding-bottom: 1px;
}

.bar {
  align-items: center;
  background: #494e36;
  color: @bar-text-color;
  height: @bar-height;
  justify-content: center;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-size: 12px;

  a {
    align-items: center;
    color: @bar-text-color;
    display: flex;
    height: 100%;
    justify-content: center;
    text-decoration: none;
    width: 100%;
  }

  .arrow {
    margin: 0 1em;
    border: 0 none;
    border-style: solid;
    border-color: transparent;
    border-top-color: @bar-text-color;
    border-width: 5px 5px 0 5px;
  }
}

#streams {
  display: grid;
  grid-column-gap: 0;
  grid-template-columns: repeat(9, 1fr);
  justify-items: left;
  margin-top: 20px;
  overflow: hidden;

  @media @phone-only {
    grid-template-columns: repeat(5, 1fr);
  }

  li {
    overflow: hidden;
    text-align: center;
    max-width: @circle-size;

    .photo, .go-live {
      align-items: center;
      background-color: #000;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      border-radius: 50%;
      border: 4px #2d2d2d solid;
      display: flex;
      height: @circle-size;
      justify-content: center;
      margin: 0 auto;
      text-transform: uppercase;
      width: @circle-size;
      font-size: 11px;
    }
    .go-live {
      background-color: #494e36;
      color: @bar-text-color;
    }
    .caption {
      visibility: hidden;
      opacity: 0;
      color: #fff;
      font-size: 11px;
      font-weight: bold;
      margin: 0.5em 0;
      text-transform: uppercase;
      &:empty:before {
        content: "\00a0";
      }
    }
    &:hover {
      width: auto;

      .caption {
        position: relative;
        z-index: 99;
        opacity: 1;
        transition: visibility 0s, opacity 0.2s linear;
        visibility: visible;
      }
    }
  }
}
