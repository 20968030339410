@import "main/styles/index.less";

.modal-container {
  background-color: #2c2c2c;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
  font-family: Helvetica, Arial, sans-serif;
  height: 500px;
  margin: 0px auto;
  padding: 0;
  position: relative;
  transition: all .3s ease;
  width: 800px;

  .split-area-2 {
    display: flex;
    flex-direction: column;
    height: 100%;

    /deep/ .members {
      z-index: 10001;
    }
  }

  .livechat {
    position: initial;
    flex-grow: 1;
    width: 100%;
    z-index: 10000;
  }

  &.fullscreen {
    height: 100%;
    width: 100%;
  }

  @media @phone-only {
    width: 100%;
  }

  .icon-close {
    left: 0;
    position: absolute;
    z-index: 101;
  }

  .btn {
    border-radius: 25px;
  }

  .live-icon {
    position: absolute;
    left: 10px;
    top: 10px;
  }
}

.video-feed-container {
  height: 100%;
  overflow: hidden;
  width: 100%;
}

.not-supported {
  color: @white;
  font-family: gotham_bookregular;
  font-size: 14px;
  padding-left: 25px;
  padding-right: 25px;
  text-align: center;

  .question-mark {
    color: #cfcfcf;
    font-size: 55px;
    padding-bottom: 15px;
    padding-top: 25px;
  }

  button {
    margin-top: 10px;
  }
}
