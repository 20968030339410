@import "frontend/pwa/sass/_variables.scss";

.live-room-members {
  position: relative;
}

.bar {
  background-color: #404040;
  color: white;
  font-family: gotham_boldregular;
  padding: 10px;
  text-transform: uppercase;

  .eye-container {
    display: inline-block;
    position: relative;
    height: 15px;
    width: 30px;

    .eye {
      position: absolute;
      top: 3px;
    }
  }

  .down-arrow:after {
    border: 0 none;
    border-style: solid;
    border-width: 10px 10px 0 10px;
    border-color: transparent;
    border-top-color: #ffffff;
    content: '\0020';
    cursor: pointer;
    float: right;
    font-size: 14px;
    height: 0;
    margin-top: 6px;
    width: 0;
  }

  .up-arrow:after {
    border: 0 none;
    border-style: solid;
    border-width: 0 10px 10px 10px;
    border-color: transparent;
    border-bottom-color: #ffffff;
    content: '\0020';
    cursor: pointer;
    float: right;
    font-size: 14px;
    height: 0;
    margin-top: 6px;
    width: 0;
  }
}

.members {
  background-color: #626161;
  padding-right: 15px;
  position: absolute;
  width: 100%;

  ul {
    max-height: 90%;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 3px;
    }
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 0 transparent;
      border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb {
      background: #d8d8d8;
      border-radius: 5px;
    }
  }

  li {
    align-items: center;
    display: flex;
    list-style-type: none;
    padding: 10px 5px 10px 5px;

    &:first-child {
      padding-top: 20px;
    }

    .avatar {
      height: 30px;

      img {
        border-radius: 50%;
        height: 30px;
        margin-right: 10px;
        width: 30px;
      }
    }

    .username {
      color: #d8d8d8;
      font-family: gotham_boldregular;
      cursor: pointer;
      text-decoration: none;
      text-transform: uppercase;
    }
  }
}
