@import "main/styles/index.less";

div {
  text-align: center;
}

h1 {
  color: white;
  margin: 0 10px 15px 10px;
  text-transform: uppercase;
  text-align: center;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  background: rgba(0, 0, 0, 0.15);
  padding: 4px;
  line-height: 1.3em;

  @media @phone-only {
    font-size: 12px;
    margin: 10px 9px;
  }

  a {
    color: white;
    text-decoration: none;
  }

  span {
    display: inline-block;
  }
  .pop {
    text-decoration: underline;
  }
}

.ad-bg {
  background: black;
}

.join-button {
  background-color: white;
  display: inline-block;
  font-weight: bold;
  padding: 10px 20px 10px 20px;
  text-transform: uppercase;

  @media @phone-only {
    font-size: 12px;
    padding: 10px 20px 10px 20px;
  }
}
